import React from 'react';
import {
  Form,
  Button
} from 'react-bootstrap';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import ScrollTop from './ScrollTop';
import logo from './logo.svg';
import instagram from './instagram.svg';

const url =
  '//duetdate.us4.list-manage.com/subscribe/post?u=c27242a3c29e055e54c23d8e0&id=77fb4e187a';

const scrollTop = () => {
  window.scrollTo(0, 0);
}

const CustomForm = ({ status, message, onValidated }) => {
  let email;
  const submit = () =>
    email &&
    email.value.indexOf('@') > -1 &&
    onValidated({
      EMAIL: email.value
    });

  return (
    <div className="form">
      <Form.Control
        size="lg"
        type="email"
        placeholder="Your Email Address"
        ref={node => (email = node)}
      />
      <Button variant="primary" size="lg" onClick={submit}>
        Join the Beta
      </Button>
      {status === 'sending' && <div className="form-message">Sending...</div>}
      {status === 'error' && (
        <div
          className="form-message error"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
      {status === 'success' && (
        <div
          className="form-message success"
          dangerouslySetInnerHTML={{ __html: message }}
        />
      )}
    </div>
  );
};

function App() {
  return (
    <ScrollTop>
      <div id="App">
        <section>
          <img src={logo} className="logo" alt="Duet" />
          <br /><br />
          <h2>A dating and social app for the rest of us.</h2>
          <br />
          <MailchimpSubscribe
            url={url}
            render={({ subscribe, status, message }) => (
              <CustomForm
                status={status}
                message={message}
                onValidated={formData => subscribe(formData)}
              />
            )}
          />
        </section>
        <section>
          <p><strong>Duet is an upcoming dating and social app.</strong> Safer, more inclusive, and much more exciting. Pair your profile with others to create a group, and match with other groups near you. A group can be whatever you want: couples, parties, events, or anything else you dream up.</p>
        </section>
        <section>
          <Button size="lg" onClick={scrollTop}>Join the beta to be first in line</Button>
          <br /><br />
          <a href="https://www.instagram.com/duetdate/" target="_blank" rel="noopener noreferrer" title="Duet on Instagram"><img src={instagram} alt="Instagram" /></a>
        </section>
      </div>
    </ScrollTop>
  );
}

export default App;
